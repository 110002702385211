/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable
import { useEffect, useMemo, useState } from 'react';
import dotenv from 'dotenv';
import Button from '@atlaskit/button';
import LoadingButton from '@atlaskit/button/loading-button';
import Select, { OptionType } from '@atlaskit/select';
import { useAuthState } from '@contexts/auth.context';
import { useSessionsCount } from '@controllers/sessionsFirestoreController';
import {
  cancelBookingWithId,
  deleteFieldFromBooking,
  updateBooking,
} from '@controllers/trainerBookingController';
import {
  deleteUser,
  useProgressDetails,
} from '@controllers/userFirestoreController';
import { Booking, Session } from '@models/booking';
import { AddRecurringSession } from '@root/components/modals/BookRecurringSessionModal';
import Upload from '@root/components/upload';
import {
  BookingConfirmationOptions,
  BookingStatusOptions,
  NoOfPeopleOptions,
  BookingLateCancellationOptions,
} from '@root/utils/common/data';
import { BookingState } from '@root/utils/enums/booking.enums';
import useUserService from '@services/useUserService';
import { convertBookingToSessionFormat } from '@utils/functions';
import _ from 'lodash';
import { useHistory, useParams } from 'react-router-dom';
import { fetchTrainers, getTrainer } from '@root/controllers/trainerFirestoreController';
import WarningModal from '@root/components/modals/WarningModal';
import UpdateMobile from '@root/components/modals/UpdateMobileNumberModal';
import collections from '@root/utils/enums/collections.enums';
import { ClientPackage } from '@root/models/clientPackage';
import { Trainer } from '@root/models/trainer';
import { User } from '@root/models/user';
import AddCheckups from '../../components/modals/AddNewCheckupModal';
import AddPackage from '../../components/modals/AddNewPackageModal';
import AddSession from '../../components/modals/BookNewSessionModal';
import { db } from '../../controllers/firebase';
import {
  ClientPackagesHead,
  userProfileHead,
  userProfileNutritionHead,
  userTransactionHead,
} from '../../components/table/content/Head';
import {
  ClientPackagesRows,
  UserProfileNutritionProgressRows,
  UserSessionRows,
  UserTransactionsRows,
} from '../../components/table/content/rows';
import Table from '../../components/table/table';
import { UseTransactionsFirestore } from '../../controllers/userTransactionsFirestoreController';
import StyledLabelTitle from '../../utils/styles/StyledLabelTitle';
import StyledLabelTitleLarge from '../../utils/styles/StyledLabelTitleLarge';
import UserProfileTopBottom from './userProfileBottom';
import UserProfileBottomInfo from './userProfileBottomInfo';
import UserProfileTop from './userProfileTop';

dotenv.config();

export default function UserInfoMain() {
  const { user: admin } = useAuthState();

  const history = useHistory();
  const params = useParams() as { id: string };
  const { user, userBookings } = useUserService(params.id);
  const [totalSessionData, setTotalSessionData] = useState<Session[]>([]);
  const [removingUser, setRemovingUser] = useState<boolean>(false);
  const [clientPackages, setClientPackages] = useState([]);
  const { transactionInfo, transactions } = UseTransactionsFirestore(params.id);
  const { progressDetails } = useProgressDetails(params.id);

  const [loadingClientPackage, setLoadingClientPackage] = useState(true);

  // eslint-disable-next-line max-len
  const [bookingLateCancellationFilter, setbookingLateCancellationFilter] = useState<null | boolean>(null);
  const [bookingStatusFilter, setBookingStatusFilter] = useState<string | null>(
    'All',
  );
  const [bookingConfirmationFilter, setBookingConfirmationFilter] = useState<
    string | null
  >('All');
  const [noOfPeopleFilter, setNoOfPeopleFilter] = useState<string | null>(
    'All',
  );
  const { count: completedSession } = useSessionsCount({
    userId: params.id,
    status: BookingState.COMPLETED,
    field: 'status',
  });

  const [PTClientTrainer, setPTClientTrainer] = useState<
    { id: string; name: string } | undefined
  >(undefined);

  const [nearTrainers, setNearTrainers] = useState<(Trainer & User)[]>([]);

  const trainerOptions: OptionType[] = useMemo(() => {
    const options = _.map(nearTrainers || [], (trainer) => ({
      value: trainer.id!,
      label: trainer.displayName || trainer.fullname || '',
    }));

    return options;
  }, [user, nearTrainers]);

  useEffect(() => {
    fetchTrainers().then((results) => {
      setNearTrainers(results);
    });
  }, []);

  useEffect(() => {
    const getPTTrainer = async () => {
      const selectedTrainer = await getTrainer(
        user?.assignedTrainerId as string,
      );
      setPTClientTrainer({
        id: selectedTrainer.id,
        name: selectedTrainer.displayName || selectedTrainer.fullname,
      });
    };
    if (user?.assignedTrainerId) {
      getPTTrainer();
    }
  }, [user?.assignedTrainerId]);

  useEffect(() => {
    const parseBookingData = async (bookingData: Booking[]) => {
      try {
        const data: Session[] = await convertBookingToSessionFormat({
          bookingList: bookingData,
          isTrainer: false,
        });
        setTotalSessionData(data);
      } catch (error) {
        setTotalSessionData([]);
      }
    };
    if (userBookings.length > 0) {
      parseBookingData(userBookings);
    }
    return () => {};
  }, [userBookings]);

  useEffect(() => {
    if (user?.id) {
      db.collection(collections.CLIENT_PACKAGES)
        .where('userId', '==', user?.id)
        .orderBy('createdAt', 'desc')
        .onSnapshot(async (snapshot) => {
          const docs = snapshot.docs.map((item) => item.data());
          for (let i = 0; i < docs.length; i += 1) {
            const clientPackage = docs[i] as ClientPackage;
            // eslint-disable-next-line no-await-in-loop
            const scheduledBookings = await db.collection(collections.BOOKINGS).where('clientPackageId', '==', clientPackage.id).where('status', '==', BookingState.SCHEDULED).get();
            docs[i].scheduledBookings = scheduledBookings.size;
            // eslint-disable-next-line no-await-in-loop
            const completedBookings = await db.collection(collections.BOOKINGS).where('clientPackageId', '==', clientPackage.id).where('status', '==', BookingState.COMPLETED).get();
            docs[i].completedBookings = completedBookings.size;
            // eslint-disable-next-line no-await-in-loop
            const existingBookings = await db.collection(collections.BOOKINGS).where('clientPackageId', '==', clientPackage.id).limit(1).get();
            docs[i].canRemove = true;
            if (!existingBookings.empty) {
              docs[i].canRemove = false;
            }
          }
          setClientPackages(docs as any);
          setLoadingClientPackage(false);
        });
    }
  }, [user]);

  const cancelBooking = async (bookingId: string) => {
    try {
      await cancelBookingWithId(bookingId, admin?.id);
    } catch (error) {
      console.log(error);
    }
  };

  const removeUser = async () => {
    setRemovingUser(true);
    await deleteUser({ userId: params.id, isTrainer: false });
    setRemovingUser(false);
    history.goBack();
  };

  const filteredSessionData = useMemo(() => {
    let filtered: Session[] = [];

    if (bookingStatusFilter === 'All' && bookingConfirmationFilter === 'All') {
      filtered = totalSessionData;
    } else if (
      bookingStatusFilter !== 'All'
      && bookingConfirmationFilter !== 'All'
    ) {
      filtered = _.filter(
        totalSessionData,
        (item: Session) => item.status === bookingStatusFilter
          && item.confirmation === bookingConfirmationFilter,
      );
    } else {
      filtered = _.filter(
        totalSessionData,
        (item: Session) => item.status === bookingStatusFilter
          || item.confirmation === bookingConfirmationFilter,
      );
    }
    if (noOfPeopleFilter !== 'All') {
      filtered = _.filter(
        filtered,
        (item: Session) => item.type.value === Number(noOfPeopleFilter),
      );
    }

    if (bookingLateCancellationFilter !== null) {
      filtered = _.filter(
        filtered,
        (item: Session) => item.lateCancellation === (bookingLateCancellationFilter),
      );
    }
    return filtered;
  }, [
    bookingConfirmationFilter,
    bookingStatusFilter,
    noOfPeopleFilter,
    totalSessionData,
    bookingLateCancellationFilter,
  ]);

  // const getRejectedSessions = useMemo(() => {
  //   const indices = filteredSessionData.reduce((acc, item, index) => {
  //     return acc.concat(
  //       item?.confirmation === BookingConfirmation.REJECTED ||
  //         (item?.cancellationReason &&
  //           item?.cancellationReason.includes("REJECTION") &&
  //           item?.status !== BookingState.COMPLETED)
  //         ? index
  //         : []
  //     );
  //   }, [] as number[]);
  //   return indices;
  // }, [filteredSessionData]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <StyledLabelTitle title="User Information" />
        <div style={{ display: 'flex' }}>
          <UpdateMobile
            userId={params.id}
            existingNumber={user?.mobile as any}
          />
          <WarningModal
            title="Are you sure you want to delete this user?"
            onPrimaryAction={() => removeUser()}
            primaryButtonLabel="Delete User"
            triggerButton={(
              <LoadingButton
                isLoading={removingUser}
                appearance="primary"
                style={{
                  backgroundColor: '#FA5D7C',
                  marginTop: 0,
                  marginRight: 20,
                }}
              >
                Delete User
              </LoadingButton>
            )}
          />
        </div>
      </div>

      {user ? (
        <>
          <div>
            <UserProfileTop user={user} />
          </div>

          <div style={{ marginTop: 40 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledLabelTitle title="Current Subsciption infomation" />

              <div style={{ marginLeft: 30 }}>
                <AddPackage userId={params.id} user={user} />
              </div>
            </div>

            <div style={{ marginTop: 50 }}>
              <StyledLabelTitle title="Client Packages" />

              <div style={{ marginTop: 20, marginLeft: 20 }}>
                <Table
                  isLoading={loadingClientPackage}
                  headType={ClientPackagesHead}
                  row={ClientPackagesRows(clientPackages, trainerOptions as any)}
                />
              </div>
            </div>
            {/* <UserProfileTopBottom user={user} /> */}
          </div>
          <div style={{ marginTop: 100 }} className="Bottom-2">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginTop: 25 }}>
                <StyledLabelTitleLarge title="User Sessions" />
              </div>

              <div style={{ marginLeft: 30, marginTop: 25 }}>
                <AddSession user={user} trainerInfo={PTClientTrainer} />
              </div>
              <div style={{ marginLeft: 30, marginTop: 25 }}>
                <AddRecurringSession user={user} />
              </div>
              <div style={{ width: 150, marginLeft: 30 }}>
                <label style={{ color: '#121212' }}>User Status</label>
                <div style={{ marginTop: 5 }}>
                  <Select
                    inputId="multi-select-example"
                    className="multi-select"
                    classNamePrefix="react-select"
                    options={BookingStatusOptions}
                    isSearchable={false}
                    placeholder="All"
                    onChange={(option) => {
                      setBookingStatusFilter(option?.value || null);
                    }}
                  />
                </div>
              </div>
              <div style={{ width: 150, marginLeft: 30 }}>
                <label style={{ color: '#121212' }}>Trainer Status</label>
                <div style={{ marginTop: 5 }}>
                  <Select
                    inputId="multi-select-example"
                    className="multi-select"
                    classNamePrefix="react-select"
                    options={BookingConfirmationOptions}
                    isSearchable={false}
                    placeholder="All"
                    onChange={(option) => {
                      setBookingConfirmationFilter(option?.value || null);
                    }}
                  />
                </div>
              </div>
              <div style={{ width: 150, marginLeft: 30 }}>
                <label style={{ color: '#121212' }}>Late Cancellation</label>
                <div style={{ marginTop: 5 }}>
                  <Select
                    inputId="multi-select-example"
                    className="multi-select"
                    classNamePrefix="react-select"
                    options={BookingLateCancellationOptions}
                    isSearchable={false}
                    placeholder="All"
                    onChange={(option) => {
                      setbookingLateCancellationFilter(option?.value as any);
                    }}
                  />
                </div>
              </div>
              <div style={{ width: 150, marginLeft: 30 }}>
                <label style={{ color: '#121212' }}>Type</label>
                <div style={{ marginTop: 5 }}>
                  <Select
                    inputId="multi-select-example"
                    className="multi-select"
                    classNamePrefix="react-select"
                    options={NoOfPeopleOptions}
                    isSearchable={false}
                    placeholder="All"
                    onChange={(option) => {
                      setNoOfPeopleFilter(option?.value || null);
                    }}
                  />
                </div>
              </div>
            </div>

            <div style={{ marginTop: 20, marginLeft: 20 }}>
              <Table
                headType={userProfileHead}
                // highlightedRowIndex={getRejectedSessions}
                row={UserSessionRows(
                  filteredSessionData,
                  user,
                  cancelBooking,
                  updateBooking,
                  deleteFieldFromBooking,
                )}
              />
            </div>
          </div>
          <div style={{ marginTop: 40 }} className="Bottom-3">
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <StyledLabelTitle title="Nutrition" />
              <div style={{ marginLeft: 30 }}>
                <AddCheckups user={user} userId={params.id} />
              </div>
              <div style={{ marginLeft: 30 }}>
                <Upload
                  label="Upload Body Analysis Report"
                  userId={user?.id as string}
                  existingFile={user?.bodyAnalysisReport}
                  keyToUpdate="bodyAnalysisReport"
                  notification={{
                    title: 'New Body Checkup',
                    body: 'Your last body checkup is available now. Check out your progress and latest acheivements!',
                    expoPushToken: user?.fcmDeviceToken as string,
                    data: {
                      url: `fitlov://progress/${user?.id}`,
                    },
                  }}
                />
              </div>
              <div style={{ marginLeft: 30 }}>
                <Upload
                  label="Upload Personalized Nutrition Guide"
                  userId={user?.id as string}
                  existingFile={user?.nutritionGuide}
                  keyToUpdate="nutritionGuide"
                  notification={{
                    title: 'New Body Checkup',
                    body: 'Your last body checkup is available now. Check out your progress and latest acheivements!',
                    expoPushToken: user?.fcmDeviceToken as string,
                    data: {
                      url: `fitlov://progress/${user?.id}`,
                    },
                  }}
                />
              </div>
              <div style={{ marginLeft: 30 }}>
                {user?.bodyAnalysisReport && (
                  <Button
                    appearance="link"
                    href={user?.bodyAnalysisReport}
                    target="_blank"
                  >
                    View Report
                  </Button>
                )}
                {user?.nutritionGuide && (
                  <Button
                    href={user?.nutritionGuide}
                    appearance="link"
                    target="_blank"
                  >
                    View Guide
                  </Button>
                )}
              </div>
            </div>
            <div style={{ marginTop: 20, marginLeft: 20 }}>
              <Table
                defaultSortKey="Date"
                headType={userProfileNutritionHead}
                // row={UserProfileNutritionRows(data)}
                row={UserProfileNutritionProgressRows(
                  progressDetails[0]?.progress || [],
                  params.id,
                )}
              />
            </div>
          </div>
          <div className="Bottom-4" style={{ marginTop: 100 }}>
            <StyledLabelTitle title="User Historical Information" />

            <UserProfileBottomInfo
              transactionInfo={transactionInfo}
              completedSession={completedSession}
            />
            <UserProfileTopBottom user={user} />
            {/* <div style={{ marginTop: 100 }}>
              <StyledLabelTitle title="Client Packages" />

              <div style={{ marginTop: 20, marginLeft: 20 }}>
                <Table
                  headType={ClientPackagesHead}
                  row={ClientPackagesRows(clientPackages, trainerOptions as any)}
                />
              </div>
            </div> */}

            <div style={{ marginTop: 100 }}>
              <StyledLabelTitle title="Transactions" />

              <div style={{ marginTop: 20, marginLeft: 20 }}>
                <Table
                  headType={userTransactionHead}
                  row={UserTransactionsRows(transactions, trainerOptions as any)}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
}
