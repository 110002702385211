/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import { BillingPaidBy } from '@utils/enums/booking.enums';
import firebase from 'firebase';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { UserBillingDetails } from '../models/billingDetails';
import { Transaction, TransactionInfo } from '../models/transactions';
import collections from '../utils/enums/collections.enums';
import CouponCodeController from './cuponCodesFirestoreController';
import { db } from './firebase';

export const UseTransactionsFirestore = (userId: string) => {
  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const [transactionInfo, setTransactionInfo] = useState<TransactionInfo>({
    totalSessions: 0,
    totalAmount: 0,
    totalTransactions: 0,
  });

  useEffect(() => {
    const unsubscribe = db
      .collection(collections.BILLING_HISTORY)
      .where('userId', '==', userId)
      .onSnapshot(async (snap) => {
        try {
          let totalSessions = 0;
          let totalAmount = 0;
          const newTransactions = await Promise.all(
            snap.docs.map(async (doc) => {
              const data = { ...doc.data(), id: doc.id } as UserBillingDetails;
              const dateMoment = moment(
                (data.createdAt as firebase.firestore.Timestamp).toDate(),
              );
              const date = dateMoment.format('ddd, DD MMM YY hh:mm A');
              const time = dateMoment.format('hh:mm a');
              // eslint-disable-next-line no-nested-ternary
              const sessionVal = typeof data?.sessions === 'string'
                ? parseInt(data?.sessions, 10)
                : typeof data?.sessions === 'number'
                  ? data?.sessions
                  : 0;
              const amountPaid = data?.price || 0;
              totalAmount += amountPaid || 0;
              totalSessions += sessionVal;
              const coupon = data?.couponId
                ? await CouponCodeController.getCouponCodeById(
                    data?.couponId as string,
                )
                : '';
              return {
                id: data?.id,
                date,
                createdAt: data.createdAt,
                time,
                amountPaid: amountPaid.toString(),
                sessions: sessionVal.toString(),
                typeofPayment: data.typeOfPayment,
                madeBy: data?.madeBy,
                paidBy: data?.paidBy || BillingPaidBy.Mobile,
                couponCode: coupon ? coupon?.code : '',
                fitlovPoints: data?.fitlovPoints,
                trainerName: data?.trainerName,
                trainerId: data?.trainerId,
              } as Transaction;
            }),
          );
          setTransactions(_.orderBy(newTransactions, ['createdAt'], ['desc']));
          setTransactionInfo({
            totalSessions,
            totalAmount,
            totalTransactions: newTransactions.length,
          });
        } catch (error) {
          setTransactions([]);
          setTransactionInfo({
            totalSessions: 0,
            totalAmount: 0,
            totalTransactions: transactions.length,
          });
        }
      });
    return unsubscribe;
  }, [userId]);
  return { transactions, transactionInfo };
};

export const deleteTransaction = async (id: string) => {
  // const clientPackageDoc = await db.collection(collections.CLIENT_PACKAGES)
  // .where('billingHistoryId', '==', id).get();
  // if (!clientPackageDoc.empty) {
  //   const clientPackage = clientPackageDoc.docs.map((doc) => doc.data())[0] as ClientPackage;
  //   await db.collection(collections.BILLING_HISTORY).doc(id).delete();

  //   const lastBilling = (await db.collection(collections.BILLING_HISTORY)
  //     .where('userId', '==', clientPackage.userId)
  //     .orderBy('createdAt', 'desc')
  //     .limit(1)
  //     .get()
  //   ).docs.map((doc) => doc.data())[0] as BillingHistory | undefined;

  //   if (lastBilling) {
  //     const newExpirationDate = moment(lastBilling.createdAt.toDate()).add(6, 'months');

  //     db.collection(collections.CLIENT_PACKAGES).doc(clientPackage.id).update({
  //       expirationDate: newExpirationDate.toDate(),
  //       billingHistoryId: lastBilling.id,
  //       updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  //     });
  //   }
  // }

  await db.collection(collections.BILLING_HISTORY).doc(id).delete();
};

export const deleteClientPackage = async (id: string, billingHistoryId?: string) => {
  await db.collection(collections.CLIENT_PACKAGES).doc(id).delete();
  console.log('clientPackage billingHistoryId', billingHistoryId);
  // if (billingHistoryId) {
  //   await db.collection(collections.BILLING_HISTORY).doc(billingHistoryId).delete();
  // }
};
