/* eslint-disable consistent-return */
import { ExpiredPackage } from '@root/models/expiredPackages';
// eslint-disable-next-line camelcase
import endpointFunctions from '@root/utils/endpoints';
import axios from 'axios';
import dotenv from 'dotenv';
import { Renewal } from '../models/renewal';

dotenv.config();
const API_URL = process.env.REACT_APP_FIREBASE_FUNCTION_URL;

class RenewalController {
  static async getDataOnUser()
  : Promise<{ zeroBalance:Renewal[], pending:Renewal[], renewal:Renewal[] }> {
    return new Promise((resolve) => {
      try {
        const options = { method: 'GET' };

        fetch(`${API_URL}/getActivity`, options)
          .then((response) => response.json())
          .then((response) => {
            resolve(response);
          })
          .catch((err) => console.error(err));
      } catch (error) {
        resolve([]);
      }
    });
  }

  static async getExpiredPackages() {
    const request = await axios.post(endpointFunctions.get_expired_packages);
    return request.data as ExpiredPackage[];
  }

  static async getAllUserBalance() {
    const request = await axios.post(endpointFunctions.getAllUserBalance);
    return request.data?.data as any[];
  }
}

export default RenewalController;
