/* eslint-disable import/no-unresolved */
import DynamicTable from '@atlaskit/dynamic-table';
import { HeadType, RowType, SortOrderType } from '@atlaskit/dynamic-table/dist/types/types';
import StatelessProps from '@atlaskit/dynamic-table/dist/types/components/Stateful';

import './table.css';

export default function Table({
  isLoading,
  headType, row, defaultSortKey, defaultSortOrder, ...rest
}:
  {isLoading?: boolean,
    headType: HeadType,
    row: RowType[] | undefined,
    defaultSortOrder?: SortOrderType | undefined,
    rest?: StatelessProps,
    defaultSortKey?: string}) {
  return (
    <DynamicTable
      isLoading={isLoading}
      head={headType}
      rows={row}
      rowsPerPage={10}
      defaultPage={1}
      emptyView={<h4>No Data available...</h4>}
      defaultSortKey={defaultSortKey}
      defaultSortOrder={defaultSortOrder || 'ASC'}
      {...rest}
    />
  );
}
